<template src="./index.html" />

<script>
import {
  BButton, BCard, BCardBody, BCardHeader, BCardTitle, BCol, BRow, BContainer, BImg, BFormInput, BFormDatepicker, BFormGroup, BForm, BFormFile,
} from 'bootstrap-vue'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'Index',
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BContainer,
    BImg,
    BFormInput,
    BFormDatepicker,
    BFormGroup,
    BForm,
    vSelect,
    BFormFile,
    VueGoodTable,
  },
  data() {
    return {
      table: {
        columns: [
          {
            label: 'Môn học',
            field: 'subjectName',
            thClass: 'text-center',
          },
          {
            label: 'Số tín chỉ',
            field: 'totalCreditNum',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Phòng thi',
            field: 'roomName',
            thClass: 'text-center',
            tdClass: 'text-left',
          },
          {
            label: 'Thời gian bắt đầu',
            field: 'startAt',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Thời gian kết thúc',
            field: 'endAt',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ],
        dataRows: [],
        paginationOptions: {
          enabled: false,
        },
      },
      user: getUser(),
    }
  },

  computed: {
    // calculate data from init data
    ...mapGetters({
      dataSources: 'student/examinationRooms',
    }),
    courseSemesterId() {
      const { programmes, portalProgrammeId } = this.user
      if (!portalProgrammeId || programmes.length === 0) return null
      const found = programmes.find(element => element.id === portalProgrammeId)
      return found ? found.courseSemesterId : null
    },
    semesterName() {
      const { programmes, portalProgrammeId } = this.user
      if (!portalProgrammeId || programmes.length === 0) return ''
      const found = programmes.find(element => element.id === portalProgrammeId)
      return found ? found.semesterName : ''
    },
  },

  async created() {
    await this.getInitData()
  },
  methods: {
    // handle even
    ...mapActions({
      getDataSource: 'student/getListExamination',
    }),

    async getInitData() {
      const params = {
        courseSemestersId: this.courseSemesterId,
        studentId: this.user.studentId,
      }
      await this.getDataSource(params)
      this.table.dataRows = this.dataSources
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
.title-text{
  font-size: 20px;
  color: red;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
